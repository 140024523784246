import { Select } from 'antd';
import CustomPaginationBtn from 'components/articleList/CustomPaginationBtn';
import React, { ReactNode } from 'react';
import { useIntl } from 'react-intl';

interface CareerPaginationContainerProps {
  children: ReactNode;
}

interface CareerPaginationProps {
  totalItems: number;
  setCurrentPage: React.Dispatch<React.SetStateAction<number>>;
  currentPage: number;
  pageSize: number;
  onListScroll: () => void;
}

const CareerPaginationContainer = ({
  children,
}: CareerPaginationContainerProps) => {
  return (
    <span className="py-5 px-[30px] lg:py-[60px] text-center flex justify-center items-center gap-4 bg-white lg:bg-transparent">
      {children}
    </span>
  );
};
const CareerPagination = ({
  totalItems,
  currentPage,
  setCurrentPage,
  pageSize,
  onListScroll,
}: CareerPaginationProps) => {
  const { formatMessage } = useIntl();

  const totalPage = Math.ceil(totalItems / pageSize);
  const totalPageIndexList = Array.from(Array(totalPage).keys());

  const updateCurrentPage = (pageNumber: number) => {
    setCurrentPage(pageNumber);
    onListScroll();
  };

  return (
    <CareerPaginationContainer>
      <CustomPaginationBtn
        label={formatMessage({ id: 'article.button.previous' })}
        disabled={currentPage === 1}
        onClick={() => {
          if (currentPage > 1) updateCurrentPage(currentPage - 1);
        }}
      />
      <Select
        defaultValue={1}
        style={{ width: 110 }}
        onChange={updateCurrentPage}
        options={totalPageIndexList.map((e, index) => ({
          value: index + 1,
          label: String(index + 1),
        }))}
        value={currentPage}
      />
      <CustomPaginationBtn
        label={formatMessage({ id: 'article.button.next' })}
        disabled={currentPage === totalPage}
        onClick={() => {
          if (currentPage < totalPage) updateCurrentPage(currentPage + 1);
        }}
      />
    </CareerPaginationContainer>
  );
};

export default CareerPagination;
