import { ImageGraphQLType } from 'components/types';
import React from 'react';

interface CareerHeroImagesProps {
  imageList: ImageGraphQLType[];
}

const CareerHeroImages = ({ imageList }: CareerHeroImagesProps) => {
  const numberOfCarouselSlide = imageList.length > 4 ? imageList.length / 4 : 1;

  return (
    <>
      {[...Array(numberOfCarouselSlide)].map((_, index) => (
        <div
          key={index}
          className="container mx-auto grid grid-rows-2 grid-flow-col gap-4 lg:h-[100%] lg:w-[100%]"
        >
          {imageList
            .slice(index, index + 4)
            .map(({ url, alternativeText }, idx) => {
              return (
                <div
                  key={url}
                  className={
                    (idx + 1) % 3 === 1
                      ? 'row-span-2 h-full lg:h-[366px]'
                      : 'h-[97px] lg:h-[175px]'
                  }
                >
                  <img
                    className="w-full h-full object-cover"
                    src={url}
                    alt={alternativeText}
                  />
                </div>
              );
            })}
        </div>
      ))}
    </>
  );
};

export default CareerHeroImages;
