import React from 'react';
import { Input, Select } from 'antd';
import { useIntl } from 'react-intl';
import { CheckedFilterListType, SortType } from '../../../filter/type';

const { Search } = Input;
interface SearchAndFilterOnWebProps {
  sortByCategory: SortType;
  sortByCountry?: SortType;
  sortByType?: SortType;
  setCheckedFilterList: React.Dispatch<
    React.SetStateAction<CheckedFilterListType>
  >;
  checkedFilterList: CheckedFilterListType;
  onSearch: (value: string) => void;
}

const SearchAndFilterOnWeb = ({
  sortByCategory,
  sortByCountry,
  sortByType,
  onSearch,
  setCheckedFilterList,
  checkedFilterList,
}: SearchAndFilterOnWebProps) => {
  const { formatMessage } = useIntl();

  const handleSort = (key: string = '', e: string) => {
    if (Array.isArray(e)) {
      setCheckedFilterList?.({
        ...checkedFilterList,
        [key]: e,
      });
    }
  };

  const handleMaxTagPlaceholder = (omittedValues: any[]) => {
    return (
      <p>{`+${omittedValues.length} ${formatMessage({
        id: 'careers.web.filter.more',
      })}`}</p>
    );
  };

  return (
    <div className="mx-auto w-[668px]">
      <div className="grid grid-rows-2 gap-4">
        <Search
          className="col-span-3"
          placeholder={formatMessage({ id: 'filter.search.placeholder' })}
          allowClear
          onSearch={e => onSearch(e)}
          style={{ width: '100%', color: '#C1C3C9' }}
          size="large"
        />
        <Select
          onChange={e => handleSort(sortByCountry?.key, e)}
          style={{ width: '221px' }}
          options={sortByCountry?.items}
          size="large"
          mode="multiple"
          maxTagCount="responsive"
          maxTagPlaceholder={e => handleMaxTagPlaceholder(e)}
          placeholder={formatMessage({ id: 'careers.web.filter.countryAll' })}
          defaultValue={
            checkedFilterList.location?.length == 0
              ? []
              : [checkedFilterList.location?.[0]]
          }
        />
        <Select
          onChange={e => handleSort(sortByCategory?.key, e)}
          style={{ width: '221px' }}
          options={sortByCategory?.items}
          size="large"
          mode="multiple"
          maxTagCount="responsive"
          maxTagPlaceholder={e => handleMaxTagPlaceholder(e)}
          placeholder={formatMessage({
            id: 'careers.web.filter.departmentAll',
          })}
        />
        <Select
          onChange={e => handleSort(sortByType?.key, e)}
          style={{ width: '221px' }}
          options={sortByType?.items}
          size="large"
          mode="multiple"
          maxTagCount="responsive"
          maxTagPlaceholder={e => handleMaxTagPlaceholder(e)}
          placeholder={formatMessage({
            id: 'careers.web.filter.jobTypeAll',
          })}
        />
      </div>
    </div>
  );
};

export default SearchAndFilterOnWeb;
