import React from 'react';
import LanguageProvider from 'LanguageProvider/index';
import translation from './translation';
import CareersHero from './CareersHero';
import CareersFilterAndListing from './CareersFilterAndListing';
import { graphql, useStaticQuery } from 'gatsby';
import { SortType } from 'components/filter/type';
import { JobCardAPIType } from 'components/types';

const Careers = () => {
  const data = useStaticQuery(graphql`
    query list {
      allStrapiJobLocation {
        nodes {
          name
          slug
        }
      }
      allStrapiJobType {
        nodes {
          name
          slug
        }
      }
      allStrapiJobCategory {
        nodes {
          name
          slug
        }
      }
      localSearchJobs {
        index
        store
      }
    }
  `);

  const convertFilterListData = (
    data: Queries.STRAPI_CAREERS_CONTENTLocation[],
    key: string,
    label: string,
  ) => {
    return {
      key,
      label,
      items: data?.map(({ name, slug }) => ({
        value: slug as string,
        label: name as string,
      })),
    };
  };

  const jobCategoryList: SortType = convertFilterListData(
    data?.allStrapiJobCategory?.nodes,
    'category',
    'Category',
  );
  const jobLocationList = convertFilterListData(
    data?.allStrapiJobLocation?.nodes,
    'location',
    'Location',
  );
  const jobTypeList = convertFilterListData(
    data?.allStrapiJobType?.nodes,
    'type',
    'Type',
  );
  const jobData = data?.localSearchJobs;
  return (
    <LanguageProvider messages={translation}>
      <CareersHero />
      <CareersFilterAndListing
        categoryList={jobCategoryList}
        locationList={jobLocationList}
        typeList={jobTypeList}
        jobData={jobData}
        defaultJobList={
          Object.values(jobData?.store)?.reverse() as JobCardAPIType[]
        }
      />
    </LanguageProvider>
  );
};

export default Careers;
