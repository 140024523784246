import { SortType } from 'components/filter/type';
import React, { ReactNode } from 'react';
import tw from 'twin.macro';

interface FilterCareerLayoutProps {
  mobileFilterList: SortType[];
  listingComponent: ReactNode;
  filterOnMobile?: ReactNode;
  filterOnWeb?: ReactNode;
}
const FilterAndListingWrapper = tw.div`
`;
const WebContainer = tw.div`
  hidden lg:flex justify-center z-[2] pb-6
`;
const MobileContainer = tw.div`
  lg:hidden z-[2] bg-white
`;

const FilterCareerLayout = ({
  listingComponent,
  filterOnWeb,
  filterOnMobile,
}: FilterCareerLayoutProps) => {
  return (
    <FilterAndListingWrapper>
      <WebContainer>{filterOnWeb}</WebContainer>
      <MobileContainer>{filterOnMobile}</MobileContainer>
      {listingComponent}
    </FilterAndListingWrapper>
  );
};

export default FilterCareerLayout;
