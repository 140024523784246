import * as React from 'react';
import translation from 'intl';
import LanguageProvider from 'LanguageProvider';
import { graphql } from 'gatsby';
import CareersIndex from 'components/pages/careers';
import NewLayout from 'components/NewLayout';
import LayoutContainer from 'components/LayoutContainer';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import SEOGraphQL from 'components/SEOGraphQL';
import { PageSEOType } from 'components/types';
import { PREFIX_CANONICAL_URL } from 'components/constant';
import BreadcrumbList from 'components/structuredData/BreadcrumbList';

const pageUrl = `${PREFIX_CANONICAL_URL}/careers-at-hubble`;

const CareersPage = () => {
  return (
    <LanguageProvider messages={translation}>
      <GoogleReCaptchaProvider
        reCaptchaKey={process.env.GATSBY_RECAPTCHA_SITE_KEY ?? ''}
      >
        <div className="lg:bg-accent_grey_1">
          <NewLayout headerStyle="light">
            <LayoutContainer>
              <BreadcrumbList
                pageLabelId="structuredData.careers"
                pageUrl={pageUrl}
              />
              <CareersIndex />
            </LayoutContainer>
          </NewLayout>
        </div>
      </GoogleReCaptchaProvider>
    </LanguageProvider>
  );
};
export default CareersPage;

export const Head = ({
  data,
}: {
  data: {
    allStrapiCareer: {
      nodes: PageSEOType[];
    };
  };
}) => {
  return (
    <SEOGraphQL data={data.allStrapiCareer.nodes[0]} canonicalURL={pageUrl} />
  );
};

export const query = graphql`
  query {
    allStrapiCareer {
      nodes {
        seo {
          preventIndexing
        }
        SEO {
          metaDescription
          metaTitle
          metaSocial {
            title
            description
            image {
              data {
                attributes {
                  url
                }
              }
            }
          }
        }
      }
    }
  }
`;
