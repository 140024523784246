import React from 'react';
import JobDescription from '../JobDescription';

import { useIntl } from 'react-intl';
import { JobCardAPIType } from 'components/types';
import { Modal } from 'antd';
import tw from 'twin.macro';

interface MobileJobDetailModalProps {
  data: JobCardAPIType;
  isModalOpen: boolean;
  setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const BackBtn = tw.button`
text-[16px] font-[500] group-hover:underline text-accent_blue_2 cursor-pointer ml-6 mb-12 mt-8
`;

const MobileJobDetailModal = ({
  data,
  isModalOpen,
  setIsModalOpen,
}: MobileJobDetailModalProps) => {
  const { formatMessage } = useIntl();

  return (
    <Modal
      open={isModalOpen}
      footer={null}
      style={{
        top: '0',
        left: '0',
        right: '0',
        background: 'white',
        marginTop: '84px',
        padding: 0,
      }}
      closable
      width={2000}
      onCancel={() => setIsModalOpen(false)}
    >
      <BackBtn onClick={() => setIsModalOpen(false)}>
        <span className="fa-light fa-arrow-left" />
        <span className="ml-4">
          {formatMessage({ id: 'careers.mobile.btn.back' })}
        </span>
      </BackBtn>
      <JobDescription hideBorder={true} data={data} />
    </Modal>
  );
};

export default MobileJobDetailModal;
