import React from 'react';
import Carousel, { ResponsiveType } from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';

interface SwipeCarouselProps {
  children: React.ReactNode;
  className?: string;
  responsive?: ResponsiveType;
  removeArrowOnDeviceType?: string[];
  autoPlaySpeed?: number;
  customDot?: React.ReactElement;
  infinite?: boolean;
}

const DefaultCarousel = ({
  children,
  responsive,
  removeArrowOnDeviceType,
  autoPlaySpeed,
  customDot,
  className,
  infinite,
}: SwipeCarouselProps) => {
  return (
    <div className={className}>
      <Carousel
        ssr
        responsive={responsive!}
        infinite={infinite}
        removeArrowOnDeviceType={removeArrowOnDeviceType}
        showDots={!!customDot}
        customDot={customDot}
        autoPlay={!!autoPlaySpeed}
        autoPlaySpeed={autoPlaySpeed}
      >
        {children}
      </Carousel>
    </div>
  );
};

export default DefaultCarousel;
