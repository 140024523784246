import React from 'react';
import DefaultCarousel from 'components/testimonial/components/Carousel';
import { ImageGraphQLType } from 'components/types';
import CareerHeroImages from './component/CareerHeroImages';

interface Props {
  imageList: ImageGraphQLType[];
  format?: string[];
}

const CareersCarouselImage = ({ imageList }: Props) => {
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };
  return (
    <DefaultCarousel
      className="w-[100%]"
      responsive={responsive}
      infinite={true}
      removeArrowOnDeviceType={['tablet', 'mobile', 'desktop']}
      autoPlaySpeed={10000}
    >
      <CareerHeroImages imageList={imageList} />
    </DefaultCarousel>
  );
};

export default CareersCarouselImage;
