import React from 'react';
import LanguageProvider from 'LanguageProvider/index';
import translation from './translation';
import tw, { styled } from 'twin.macro';
import { useIntl } from 'react-intl';
import { JobCardAPIType } from 'components/types';
import JobForm from './JobForm';
import { StrapiContentContainer } from 'components/styles';

const PageContainer = styled.div(({ hideBorder }: any) => [
  `border: ${!hideBorder ? '1px solid #EBEDF6' : 'none'}`,
  `width: 100%;`,
  `padding: 16px;`,
]);

const Title = tw.h3`
mb-2 text-base_text
`;

const SubTitle = tw.p`
mb-2 text-[24px] font-[500] leading-[27px] lg:text-[20px] lg:leading-[30px] lg:font-[400] text-base_text
`;

const Description = tw(StrapiContentContainer)`
  mb-10 text-[16px] break-words !text-subtext
`;

interface Props {
  data: JobCardAPIType;
  hideBorder?: boolean;
}

const index = ({ data, hideBorder }: Props) => {
  const { formatMessage } = useIntl();
  return (
    <LanguageProvider messages={translation}>
      <PageContainer hideBorder={hideBorder}>
        <div className="bg-white lg:py-[40px] px-[6px] lg:px-6">
          <Title>{data?.title}</Title>
          {data?.description && (
            <Description
              dangerouslySetInnerHTML={{ __html: data?.description }}
            />
          )}
          {data?.recruitmentObject && (
            <>
              <SubTitle>
                {formatMessage({
                  id: 'careers.jobDescription.recruitmentObject',
                })}
              </SubTitle>
              <Description
                dangerouslySetInnerHTML={{ __html: data?.recruitmentObject }}
              />
            </>
          )}
          <SubTitle>
            {formatMessage({ id: 'careers.jobDescription.yourMission' })}
          </SubTitle>
          <Description dangerouslySetInnerHTML={{ __html: data?.mission }} />
          <SubTitle>
            {formatMessage({ id: 'careers.jobDescription.requirement' })}
          </SubTitle>
          <Description
            dangerouslySetInnerHTML={{ __html: data?.requirement }}
          />
          <SubTitle>
            {formatMessage({ id: 'careers.jobDescription.benefit' })}
          </SubTitle>
          <Description dangerouslySetInnerHTML={{ __html: data?.benefit }} />

          <JobForm jobId={data?.id} location={data.location.name} />
        </div>
      </PageContainer>
    </LanguageProvider>
  );
};

export default index;
