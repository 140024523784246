import React, { useEffect, useMemo, useState } from 'react';
import tw from 'twin.macro';
import { CAREERS_SORT, CAREER_SORT_CATEGORY } from './constant';
import SearchAndFilterOnWeb from 'components/pages/careers/component/SearchAndFilterOnWeb';
import JobList from './JobList';
import JobDescription from './JobDescription';
import { JobCardAPIType } from 'components/types';
import FilterOnMobile from 'components/filter/FilterOnMobile';
import { graphql, useStaticQuery } from 'gatsby';
import FilterCareerLayout from './component/FilterCareerLayout';
import { CheckedFilterListType, SortType } from 'components/filter/type';
// @ts-ignore react-use-flexsearch does not support Typescript https://www.npmjs.com/package/react-use-flexsearch
import { useFlexSearch } from 'react-use-flexsearch';
import useMediaQuery from './hooks/useMediaQuery';
import { isEmpty } from 'lodash';
import { useLocation } from '@reach/router';
import NoJobFound from './component/NoJobFound';

interface CareersFilterAndListingProps {
  categoryList: SortType;
  locationList: SortType;
  typeList: SortType;
  jobData: { index: any; store: any };
  defaultJobList: JobCardAPIType[];
}
interface NoJobScreenProps {
  searchKey: string;
}
const Container = tw.div`
    grid grid-cols-1 lg:grid-cols-[480px_minmax(auto,774px)] lg:gap-4 my-6 bg-accent_grey_1
`;

const WebContainer = tw.div`
hidden lg:flex justify-center z-[2] w-full max-w-full
`;
const MobileContainer = tw.div`
lg:hidden 
`;
const RightColumnWrapper = tw.div`
lg:border lg:border-accent_grey_2 bg-white flex h-[360px] lg:h-[640px] content-center justify-center pt-[80px] w-full
`;

const NoJobScreen = ({ searchKey }: NoJobScreenProps) => {
  if (searchKey)
    return (
      <RightColumnWrapper>
        <NoJobFound
          headerId={'career.noResultsFound.header'}
          descriptionId={'career.noResultsFound.description'}
        />
      </RightColumnWrapper>
    );

  return (
    <RightColumnWrapper>
      <NoJobFound
        headerId={'career.noJobOpening.header'}
        descriptionId={'career.noJobOpening.description'}
      />
    </RightColumnWrapper>
  );
};

const CareersFilterAndListing = ({
  categoryList,
  locationList,
  typeList,
  jobData,
  defaultJobList,
}: CareersFilterAndListingProps) => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const locationQuery = searchParams.get('location');
  const isMobile = useMediaQuery('(max-width: 1024px)');

  const [selectedJob, setSelectedJob] = useState<JobCardAPIType>(
    defaultJobList[0] as JobCardAPIType,
  );
  const [checkedFilterList, setCheckedFilterList] =
    useState<CheckedFilterListType>(
      locationQuery ? { location: [locationQuery] } : { location: [] },
    );
  const [searchKey, setSearchKey] = useState('');
  const [filteredJobList, setFilteredJobList] =
    useState<JobCardAPIType[]>(defaultJobList);
  useEffect(() => {
    if (locationQuery) setCheckedFilterList({ location: [locationQuery] });
  }, [locationQuery]);

  const index = jobData?.index;
  const store = jobData?.store;

  const useFlexResult = useFlexSearch(searchKey, index, store);

  const jobList = useMemo<JobCardAPIType[]>(
    () =>
      index && store && searchKey
        ? useFlexResult
        : Object.values(store).reverse(),
    [index, store, searchKey, useFlexResult],
  );

  useEffect(() => {
    const filteredList = jobList.filter(({ category, type, location }) => {
      const isJobMatched = (arr: Array<string>, key: string) => {
        return isEmpty(arr) || arr.includes(key);
      };

      return [
        isJobMatched(
          checkedFilterList[categoryList?.key] ?? [],
          category?.slug,
        ),
        isJobMatched(checkedFilterList[typeList?.key] ?? [], type?.slug),
        isJobMatched(
          checkedFilterList[locationList?.key] ?? [],
          location?.slug,
        ),
      ].every(Boolean);
    });

    setFilteredJobList(filteredList);
  }, [checkedFilterList, jobList]);

  useEffect(() => {
    setSelectedJob(jobList[0]);
  }, [jobList]);

  const onClickSort = (value: string) => {
    const newFilteredJobList = [...filteredJobList];

    if (value === CAREER_SORT_CATEGORY.ABC) {
      newFilteredJobList.sort((a, b) => a.title.localeCompare(b.title));
    } else if (value === CAREER_SORT_CATEGORY.RECENT) {
      newFilteredJobList.sort((a, b) => b.id - a.id);
    }

    setFilteredJobList(newFilteredJobList);
  };

  const onSearch = (value: string) => {
    setSearchKey(value);
  };

  const onChangeJob = (job: JobCardAPIType) => {
    setSelectedJob(job);
  };

  const MOBILE_FILTER: SortType[] = [categoryList, locationList, typeList];
  const filterMobile = (
    <FilterOnMobile
      sortList={CAREERS_SORT}
      filterList={MOBILE_FILTER}
      setFilter={setCheckedFilterList}
      handleSearch={onSearch}
      handleSort={onClickSort}
    />
  );

  return (
    <FilterCareerLayout
      mobileFilterList={MOBILE_FILTER}
      filterOnWeb={
        <SearchAndFilterOnWeb
          sortByCategory={categoryList}
          sortByCountry={locationList}
          sortByType={typeList}
          onSearch={onSearch}
          setCheckedFilterList={setCheckedFilterList}
          checkedFilterList={checkedFilterList}
        />
      }
      filterOnMobile={filterMobile}
      listingComponent={
        <Container>
          <JobList
            list={filteredJobList}
            isMobile={isMobile}
            onChangeJob={onChangeJob}
            selectedJobId={selectedJob?.id}
            sortList={CAREERS_SORT}
            onClickSort={onClickSort}
            setSelectedJob={setSelectedJob}
          />
          <WebContainer>
            {filteredJobList.length > 0 ? (
              <JobDescription data={selectedJob} />
            ) : (
              <NoJobScreen searchKey={searchKey} />
            )}
          </WebContainer>
          {filteredJobList.length === 0 && (
            <MobileContainer>
              <NoJobScreen searchKey={searchKey} />
            </MobileContainer>
          )}
        </Container>
      }
    />
  );
};

export default CareersFilterAndListing;
