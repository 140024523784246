import React, { useEffect, useRef, useState } from 'react';
import LanguageProvider from 'LanguageProvider/index';
import translation from './translation';
import Card from './Card';
import { JobCardAPIType } from 'components/types';
import tw from 'twin.macro';
import { useIntl } from 'react-intl';
import useListScroll from 'components/articleList/hooks/useListScroll';
import { Select } from 'antd';
import { SortType } from 'components/filter/type';
import { JOBS_PER_PAGE } from './constant';
import CareerPagination from './component/CareerPagination';

const WebSortButtonWrapper = tw.div`
 hidden lg:flex items-center gap-2 text-[#9297A3] cursor-pointer
`;

const CountingText = tw.div`
  text-[20px]
`;

const ListHeader = tw.div`
  border border-accent_grey_2 bg-white flex h-24 content-center justify-center justify-between px-6
`;

interface Props {
  onChangeJob: (job: JobCardAPIType) => void;
  isMobile: boolean;
  list: JobCardAPIType[];
  selectedJobId: number;
  sortList: SortType;
  onClickSort: (value: string) => void;
  setSelectedJob: React.Dispatch<React.SetStateAction<JobCardAPIType>>;
}

const index = ({
  onChangeJob,
  isMobile,
  list,
  selectedJobId,
  sortList,
  onClickSort,
  setSelectedJob,
}: Props) => {
  const { formatMessage } = useIntl();
  const [currentPage, setCurrentPage] = useState(1);
  const listRef = useRef<HTMLDivElement>(null);
  const onListScroll = useListScroll(listRef);
  const [isSortOpen, setIsSortOpen] = useState<boolean>(false);

  const totalJob = list?.length;
  const offset = (currentPage - 1) * JOBS_PER_PAGE;
  const end =
    offset + JOBS_PER_PAGE > totalJob ? totalJob : offset + JOBS_PER_PAGE;

  const currentList = list.slice(offset, end);

  useEffect(() => {
    setSelectedJob(currentList[0]);
  }, [currentPage]);

  useEffect(() => {
    setCurrentPage(1);
  }, [list]);

  return (
    <LanguageProvider messages={translation}>
      <div>
        <ListHeader>
          <CountingText className="flex items-center">
            {`${totalJob} ${formatMessage({
              id: 'careers.jobList.jobsAvailable',
            })}`}
          </CountingText>

          <WebSortButtonWrapper
            onClick={() => {
              setIsSortOpen(!isSortOpen);
            }}
          >
            <span className="fa-solid fa-sort text-[14px] cursor-pointer" />

            <Select
              style={{
                color: '#585C67',
                fontSize: '14px',
                borderRadius: '5px',
              }}
              onChange={onClickSort}
              options={sortList?.items}
              size="large"
              defaultValue={sortList?.items[0].value}
              open={isSortOpen}
              showArrow={false}
              bordered={false}
              dropdownStyle={{ width: '190px' }}
              placement="bottomRight"
              popupClassName="newsroom-and-blog-web-sort"
            />
          </WebSortButtonWrapper>
        </ListHeader>
        <div ref={listRef}>
          {currentList?.map(card => {
            return (
              <div
                className={
                  selectedJobId === card.id ? 'lg:bg-accent_grey_2' : 'bg-white'
                }
                key={card.id}
              >
                <Card
                  onChangeJob={onChangeJob}
                  isMobile={isMobile}
                  data={card}
                />
              </div>
            );
          })}
        </div>
        {totalJob > JOBS_PER_PAGE && (
          <CareerPagination
            totalItems={totalJob}
            setCurrentPage={setCurrentPage}
            currentPage={currentPage}
            onListScroll={onListScroll}
            pageSize={JOBS_PER_PAGE}
          />
        )}
      </div>
    </LanguageProvider>
  );
};

export default index;
