import React, { useState } from 'react';
import tw, { styled } from 'twin.macro';
import { JobCardAPIType } from 'components/types';
import MobileJobDetailModal from './component/MobileJobDetailModal';

const CategoryChip = styled.div(({ backgroundColor, color }: any) => [
  `color: ${color};`,
  `background-color: ${backgroundColor}`,
  `font-weight: 600;`,
  `font-size: 12px`,
  `display: flex`,
  `align-items: center`,
  `justify-content: center`,
  `border-radius: 34px`,
  `padding: 4px 8px`,
]);

const Card = tw.div`
 cursor-pointer border border-accent_grey_2 bg-[transparent] p-6 cursor-pointer hover:opacity-70 break-words 
`;

const Info = tw.div`
  flex justify-between items-center
`;
const InfoLeftColumn = tw.div`
flex gap-2 items-center
`;
const LocationText = tw.span`
text-[14px] text-base_text
`;
const TypeText = tw.span`
text-[12px] text-[#7B7F8A]
`;
const Title = tw.p`
   text-[20px] font-[600] text-[#292D37] mt-0 mb-0
`;

interface Props {
  data: JobCardAPIType;
  onChangeJob: (job: JobCardAPIType) => void;
  isMobile: boolean;
}

const index = ({ data, onChangeJob, isMobile }: Props) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const onHandleClick = () => {
    if (isMobile) {
      setIsModalOpen(true);
    } else {
      onChangeJob(data);
    }
  };
  const {
    type: { name: jobType },
    location: { name: jobLocation },
    category: { name: jobCategory, backgroundColorCode, textColorCode },
  } = data;
  return (
    <>
      <Card onClick={onHandleClick}>
        <Info>
          <InfoLeftColumn>
            <LocationText>{jobLocation}</LocationText>
            <span className="fa-sharp fa-solid fa-circle-small text-[8px] text-[#7B7F8A]" />
            <TypeText>{jobType}</TypeText>
          </InfoLeftColumn>
          <CategoryChip
            color={textColorCode}
            backgroundColor={backgroundColorCode}
          >
            {jobCategory}
          </CategoryChip>
        </Info>
        <Title>{data.title}</Title>
        {data.description && (
          <div
            className="text-[16px] text-base_text max-h-[48px] overflow-hidden mt-1"
            dangerouslySetInnerHTML={{ __html: data.description }}
          />
        )}
      </Card>
      {isModalOpen && (
        <MobileJobDetailModal
          data={data}
          isModalOpen={isModalOpen}
          setIsModalOpen={setIsModalOpen}
        />
      )}
    </>
  );
};

export default index;
