import Link from 'components/Link';
import { useIntl } from 'react-intl';
import tw from 'twin.macro';
import React from 'react';
import { withIntl } from 'LanguageProvider/withLanguageProvider';
import translation from '../translation';

interface NoJobFoundProps {
  headerId: string;
  descriptionId: string;
}

const NoResultFoundWrapper = tw.div`
px-[30px] text-[#585C67] text-center mx-auto pb-10
`;
const NotFoundHeading = tw.p`
text-[24px] font-[500] leading-[36px] mb-2
`;
const NotFoundDescription = tw.p`
whitespace-pre-line text-[16px] leading-[24px] mb-4 lg:mb-6
`;
const StyledLink = tw(Link)`
bg-white hover:bg-white hover:opacity-70 text-hubble_blue border border-hubble_blue text-[16px] font-[500] hover:text-hubble_blue hover:bg-accent_grey_2 py-2 px-3
`;

const NoJobFound = ({ headerId, descriptionId }: NoJobFoundProps) => {
  const { formatMessage } = useIntl();
  return (
    <NoResultFoundWrapper>
      <NotFoundHeading>{formatMessage({ id: headerId })}</NotFoundHeading>
      <NotFoundDescription>
        {formatMessage({ id: descriptionId })}
      </NotFoundDescription>
      <StyledLink to="/contact-us">
        {formatMessage({ id: 'career.noResultsFound.btn' })}
      </StyledLink>
    </NoResultFoundWrapper>
  );
};
export default withIntl(translation)(NoJobFound);
