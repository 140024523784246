import tw, { styled } from 'twin.macro';

export const StrapiContentContainer = styled.div`
  ${tw`!text-left`}
  ol,
  ul {
    list-style-position: inside;
  }
  ul {
    li {
      margin-left: 1.5rem;
      list-style-type: disc;
    }
  }
  figure {
    margin: auto;
    @media (max-width: 840px) {
      width: 100% !important;
    }
    text-align: center !important;
  }
  * {
    ${tw`leading-[120%] lg:leading-[130%] mb-4 `}
  }

  h1,
  h2,
  h3,
  h4 {
    ${tw`!text-hubble_blue`}
    span {
      ${tw`!text-hubble_blue`}
    }
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    ${tw`leading-[120%] lg:leading-[130%] mt-6 mb-4`}
  }
  h1 {
    ${tw`text-[36px] lg:text-[48px] !font-[600]`}
  }
  h2 {
    border-bottom: 1px solid #a2a9b1;
    ${tw`text-[30px] lg:text-[36px] mt-[3rem] mb-[0.75rem] !font-[600]`}
  }
  h3 {
    ${tw`text-[24px] lg:text-[28px] mt-[1.5rem] mb-[0.2rem] !font-[500]`}
  }
  h4 {
    ${tw`text-[20px] lg:text-[20px] mt-[1.15rem] mb-[0.2rem] !font-[525]`}
  }
  h5 {
    ${tw`text-[18px] lg:text-[18px]`}
  }
  h6 {
    ${tw`text-[16px] lg:text-[16px]`}
  }
`;
